import React, { useContext, useEffect } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';
import SourceEmitter from 'libs/emitter';
import BrandCTA from 'components/BrandCta';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';

import NDPatientWhyHeroMobile from '../../assets/images/ND-patient-whyND-mobile.png';
import imgPayLittle from '../../assets/images/nutrition-pay-callout.png';

import step1Bullet from '../../assets/images/step1-bullet.png';
import step2Bullet from '../../assets/images/step2-bullet.png';
import step3Bullet from '../../assets/images/step3-bullet.png';

import './why-nutrition-direct.scss';

let Subscription = null;

const programdetailsPage = () => {
  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionPatient;
  // const metaData = appConfigs?.metaData?.nutritionPatient;

  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const pageTitle = 'NUTRITION DIRECT™ | Why Nutrition Direct™';
  const pageDescription = 'Learn more about the features of this program';

  const renderNutritionDirect = () => {
    return (
      <div>
        <ContentBlock
          id="why-nutrition-block"
          className="no-padding-top margin-offset"
        >
          <Grid
            fluid
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
            }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>
                    WHY
                    <br className="hide-only-mobile" />{' '}
                    <span
                      className="brandname-nutrition txt-uppercase"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.NUTRITION,
                      }}
                    />
                  </h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={NDPatientWhyHeroMobile} />
                </div>
              </Col>
            </Row>
            <ColorBlock white>
              <Row>
                <Col xs={12} md={12}>
                  <div>
                    <h2 className="strong">
                      Designed for people who have had bariatric (weight loss)
                      surgery
                    </h2>
                    <p>
                      After weight loss surgery, you have to take nutritional
                      supplements for the rest of your life because your
                      digestive tract and stomach work differently now.{' '}
                      <span
                        className="brandname-nutrition"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.NUTRITION,
                        }}
                      />{' '}
                      helps to make it easy for you to stay on track with
                      important nutrients. It gives you micronutrients
                      recommended in the American Society for Metabolic and
                      Bariatric Surgery (ASMBS) guidelines.*
                    </p>
                    <h2 className="strong top-spacer">
                      Enrolling in{' '}
                      <span
                        className="brandname-nutrition"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.NUTRITION,
                        }}
                      />{' '}
                      has many benefits
                    </h2>
                  </div>
                  <div>
                    <p className="pt-0">
                      <strong>
                        Your monthly supply of bariatric supplements are
                        delivered together to your doorstep:
                      </strong>
                    </p>
                    <ul className="list-regular">
                      <li>
                        <span
                          className="brandname-nascobal"
                          dangerouslySetInnerHTML={{
                            __html: brandNames.NASCOBAL,
                          }}
                        />{' '}
                        Vitamin B<sub>12</sub> Nasal Spray
                      </li>
                      <li>
                        <span
                          className="brandname-bariactiv"
                          dangerouslySetInnerHTML={{
                            __html: brandNames.BARIACTIV,
                          }}
                        />{' '}
                        Multivitamin, with or without Iron
                      </li>
                      <li>
                        <span
                          className="brandname-bariactiv"
                          dangerouslySetInnerHTML={{
                            __html: brandNames.BARIACTIV,
                          }}
                        />{' '}
                        Calcium + D<sub>3</sub> &amp; Magnesium
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <h2 className="strong pt-0">
                    Recommended micronutrients with the convenience of free home
                    delivery—at an affordable price
                  </h2>
                  <p>
                    When you enroll in{' '}
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.NUTRITION,
                      }}
                    />
                    , you have a lot to look forward to. Many people are
                    eligible to get{' '}
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.NUTRITION,
                      }}
                    />{' '}
                    for <span className="blue strong">as little as $0</span> per
                    month.
                    <sup className="dagger"></sup>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <ul className="list-regular">
                    <li>
                      30-day supply of nutritional supplements sent each month
                      in one simple delivery
                    </li>
                    <li>
                      <span
                        className="brandname-nascobal"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.NASCOBAL,
                        }}
                      />
                      , a prescription vitamin B
                      <sub className="vitaminb12-sub">12</sub> nasal spray
                    </li>
                    <li>
                      Micronutrients that meet specific ASMBS guidelines*
                      recommended for people who have had weight loss surgery
                    </li>
                    <li>Convenient, free home delivery</li>
                    <li>Automatic refills</li>
                    <li>Educational materials</li>
                    <li>Ongoing support</li>
                  </ul>
                </Col>
                <Col xs={12} md={6}>
                  <img src={imgPayLittle} alt="nutrition-pay-callout" />
                </Col>
              </Row>
            </ColorBlock>
          </Grid>

          <ColorBlock blue className="pb-40">
            <Row>
              <Col xs={12}>
                <h2 className="strong">
                  Enroll in{' '}
                  <span
                    className="brandname-nutrition"
                    dangerouslySetInnerHTML={{
                      __html: brandNames.NUTRITION,
                    }}
                  />
                </h2>
                <p>
                  Talk to your healthcare professional (HCP) about{' '}
                  <span
                    className="brandname-nutrition"
                    dangerouslySetInnerHTML={{
                      __html: brandNames.NUTRITION,
                    }}
                  />{' '}
                  to see if it's right for you. Enroll in just three simple
                  steps:
                </p>
              </Col>
            </Row>
            <Row id="enrollment-steps">
              <Col xs={12} md={4}>
                <div className="step-flex-container">
                  <img src={step1Bullet} />
                  <p>
                    The HCP's office will help you complete the enrollment form,
                    or may choose to ePrescribe
                  </p>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="step-flex-container">
                  <img src={step2Bullet} />
                  <p>
                    You will receive a welcome communication (text and/or phone
                    call) from the pharmacy to confirm your order
                  </p>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="step-flex-container">
                  <img src={step3Bullet} />
                  <p>
                    ProCare PharmacyCare will ship your nutritional supplements
                    to you within 3 to 5 days
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p className="pbottom8 strong blue">
                  Please contact ProCare PharmacyCare any time at 855-828-1488.
                  {/* <strong>
                    You will receive a welcome communication (text and/or phone
                    call) from the pharmacy to confirm your order.
                  </strong>{' '}
                  After that, ProCare PharmacyCare will ship your nutritional
                  supplements to you within 3 to 5 days. You will receive an
                  email confirming shipping with tracking information so you can
                  track your shipment. */}
                </p>
                <p>
                  Your order will be automatically refilled each month. The
                  pharmacy will help you with insurance claim processing each
                  month so that the nutritional supplements in{' '}
                  <span
                    className="brandname-nutrition"
                    dangerouslySetInnerHTML={{
                      __html: brandNames.NUTRITION,
                    }}
                  />{' '}
                  will arrive at your home. In addition to your nutritional
                  supplements, the package will include materials to help you
                  maintain a healthy lifestyle.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="pt-20">
                <p className="footnote asterik" style={{ marginTop: 20 }}>
                  ASMBS Integrated Health Nutritional Guidelines for the
                  Surgical Weight Loss Patient — 2016 Update: Micronutrients.
                </p>
                <p className="footnote dagger">
                  Patients may redeem this offer ONLY when accompanied by a
                  valid prescription. Offer is valid up to a maximum benefit of
                  $150. Offer is not valid for patients whose prescriptions are
                  reimbursed in whole or in part under Medicaid, Medicare,
                  Medigap, VA, DoD, TRICARE, or any other federal or state
                  programs (such as medical assistance programs) or where
                  otherwise prohibited by law. Offer is not valid in VT or where
                  prohibited in whole or in part. This offer may be amended or
                  ended at any time without notice.
                </p>
              </Col>
            </Row>
          </ColorBlock>
        </ContentBlock>
        <Row>
          <Col xs={12} md={6}>
            <BrandCTA
              href="/nutritiondirect/nasal-spray"
              LinkCaption="LEARN MORE ABOUT NASCOBAL®"
            >
              LEARN MORE ABOUT NASCOBAL
              <sup className="brand-reg-mark">&reg;</sup>
            </BrandCTA>
          </Col>
          <Col xs={12} md={6}>
            <BrandCTA
              href="/nutritiondirect/bariactiv"
              LinkCaption="ABOUT BARIACTIV® SUPPLEMENTS"
            >
              ABOUT BARIACTIV<sup className="brand-reg-mark">&reg;</sup>{' '}
              SUPPLEMENTS
            </BrandCTA>
          </Col>
        </Row>
      </div>
    );
  };

  const renderPage = () => {
    return (
      <div id="nutrition-patient-why">
        {renderNutritionDirect()}
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout
      indication={indication}
      className="nutrition-patient-programdetails-page"
    >
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default programdetailsPage;
